@import '../../../styles/variables.scss';
@import '../../HooksFormFields/form-mixin.scss';
.date-picker{

  label{
    @include label;
  }

  &.inline{
    display: flex;
    justify-content: space-between;
    align-items: center;
    label{
      margin-right: 10px;
    }
  }

  &:not(.inline){
    label+.container-input{
      margin-top: 10px;
    }
  }

  .container-input{
    position: relative;
    width: 100%;
    .container-icon{
      display: block;
      position: absolute;
      top:0;
      right:0;
      height: 36px;
      width: 36px;
      pointer-events: none;
      >svg {
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
      }
    }

    .container-delete {
      display: block;
      position: absolute;
      top:0;
      right: 20px;
      height: 36px;
      width: 36px;
      pointer-events: all;
      >svg{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
      }
    }

    >div{
      width: 100%;
    }
  }

  .day {
    @include transition;
    color: $dark;
    border-radius: 30px;
    &.selected{
      background-color: $primary-color;
      color : white;
    }

    &:not(.selected){
      background-color: transparent;
      &:hover {
        background-color: rgba($primary-color, 0.3);
      }
    }
  }

  input{
    @include label;
    border: none;
    outline: none;
    min-height: 30px;
    display: block;
    padding: 10px 36px 5px 5px;
    border: none;
    outline: none;
    background-color: white;
    font-weight: bold;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    border-bottom: 1px solid #797E95;
    &:focus{
      border-bottom: 1px solid $primary-color;
    }

    &::placeholder {
      font-size: 14px;
      color : #8c8c8c;
    }
  }

}
