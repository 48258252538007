@import '../../styles/variables.scss';

.legend {
  position: absolute;
  display: flex;
  top: 90px;
  right: 5%;
  background-color: rgba($bg-color, 0.8);
  z-index: 600;
  .item {
    display: flex;
    align-items: center;
    .prev {
      width: 21px;
      margin-right: 10px;
      display: flex;
      justify-content: space-between;
      .prev-item {
        width: 10px;
        background-color: #BEB2EF;
        height: 6px;
      }
    }
    .reel {
      width: 18px;
      height: 22px;
      background-color: #BEB2EF;
      margin: 0px 10px 0px 20px;
    }
    .icon {
      width: 13px;
      height: 13px;
      border: 1px solid #9286C4;
      background-color: rgba(239, 177, 158, 1);
      box-shadow: 0px 10px 28px #EBECED;
      border-radius: 1px;
      z-index: 2;
      margin: 0px 10px 0px 20px;
      transform: rotate(45deg);
      &.rms {
        background-color: rgba(127, 227, 204, 1);
      }
      &.ansm {
        background-color: rgba(209, 246, 130, 1);
      }
    }
    p {
      color:rgba($dark, 0.6);
    }
  }
}

.container {
  @include page-container;
  .content {
    position: relative;
    width: 100%;
    margin-top: 100px;
    height: calc(100% - 100px);
    overflow: auto;
    .dates {
      position: fixed;
      top: 80px;
      height: 100vh;
      z-index: 0;
      pointer-events: none;
      transform: translateX(368px);
      display: flex;
      .month {
        margin-top: 100px;
        min-height: 100%;
        border-left: 1px solid rgba($dark, 0.2);
        p {
          font-weight: bold;
          text-transform: uppercase;
          color:rgba($dark, 0.6);
          width: 100%;
          text-align: center;
          margin: 0px;
          padding: 5px;
          transform: translate(-50% , -150%);
        }
        &.is-active {
          border-left: 2px dashed rgba(#23B1A9, 1 );
          p {
            background-color: #23B1A9;
            padding: 5px;
            border-radius: 20px;
            color: $white;
          }
        }
      }
    }
    .planning {
      position: absolute;
      padding-top: 40px;
      transform: translate(360px);
    }
    .products {
      position: sticky;
      left: 0px;
      margin-top: 20px;
      padding-top: 20px;
      padding-left: 40px;
      max-width: 320px;
      height: 100%;
      background-color: rgba($bg-color, 0.8);
      .name {
        @include box;
        width: 280px;
        padding: 20px;
        font-weight: bold;
      }
    }  
  }
}