@import '../../../../styles/variables.scss';

.container {
  border-top: 1px solid #E4EAEE;
  padding: 30px;
  width: 100%;
  h2 {
    margin-top: 0px;
    font-size: 16px;
    font-weight: bold;
  }
  .boolean-row {
    margin-top: 30px;
    width: 100%;
    display: flex;
    .col {
      width: 180px;
      padding-right: 20px;
      &:not(:first-child) {
        border-left: 1px solid #E4EAEE;
        padding-left: 20px;
      }
      label {
        @include label;
        display: inline-block;
        margin-bottom: 5px;
        color: rgba($dark , 0.5);
      }
      p {
        margin: 0px;
        font-weight: bold;
      }
    }
  }
  .row {
    margin-top: 30px;
    width: 100%;
    display: flex;
    .col {
      width: 50%;
      min-height: 200px;
      padding-right: 20px;
      &:not(:first-child) {
        border-left: 1px solid #E4EAEE;
        padding-left: 20px;
      }
      label {
        @include label;
        display: inline-block;
        margin-bottom: 5px;
        color: rgba($dark , 0.5);
      }
      p {
        margin: 0px;
        font-weight: bold;
      }
      pre {
        @include text;
        white-space: pre-wrap; 
      }
    }
  }
} 