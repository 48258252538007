@import '../../../../styles/variables.scss';

.container {
  display: flex;
  width: 100%;
  padding: 50px 30px 30px 30px;
  label {
    @include label;
    display: inline-block;
    margin-bottom: 5px;
    color: rgba($dark , 0.5);
    &:not(:first-child) {
      margin-top: 15px;
    }
  }
  .col {
    width: 40%;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &:not(:first-child) {
      border-left: 1px solid #E4EAEE;
      padding-left: 20px;
    }
    &:nth-child(2n) {
      width: 20%;
    }
    .select {
      width: 100%;
    }
    pre {
      @include text;
      white-space: pre-wrap; 
    }
    h3 {
      margin: 0px;
      @include title-3;
    }
  }
}