@import '../../../../styles/variables.scss';

.container {
  width: 100%;
  .files {
    .file {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      background-color: $primary-light;
      border-radius: $radius;
      button {
        @include btn;
        display: flex;
        justify-content: center;
        margin: 5px;
        align-items: center;
        &:first-child {
          padding: 0px;
          color: $dark;
          font-size: 14px;
          p {
            margin: 5px;
            font-family: "Ubuntu-Regular";
            font-size: 14px;
            text-decoration: underline;
          }
        }
      }
    }
  }
  h2 {
    margin-top: 0px;
    font-size: 16px;
    font-weight: bold;
  }
  .input {
    margin-top: 10px;
    width: 100%;
  }
}