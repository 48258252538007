@import '../../styles/variables.scss';

.btn-container {
  position: absolute;
  top: 55px;
  right: 5%;
  z-index: 300;
  display: flex;
  a {
    text-decoration: none;
  }
}

@mixin icon {
  @include transition;
  position: relative;
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  border: 1px solid #9286C4;
  box-shadow: 0px 10px 28px #EBECED;
  border-radius: 1px;
  z-index: 2;
  transform: rotate(45deg);
  cursor: pointer;
  margin-right: 8px;
  &.submition {
    background-color: #F8BBBB;
  }
  &.approbation-ANSM {
    background-color: rgba(209, 246, 130, 1);
  }
  &.date {
    background-color: #7FE3CC;
  }
}

.container {
  @include page-container;
  display: flex;
  justify-content: center;
  .sort {
    position: absolute;
    width: 100%;
    top: 35px;
    left: -5px;
    padding: 10px 2%;
    @media only screen and (min-width: $lg-screen) {
      padding: 10px 5%;
    }
  }
  .content {
    @include scrollbar;
    width: 100%;
    margin-top: 120px;
    overflow-y: auto;
    height: calc(100vh - 200px);
    padding: 10px 2%;
    @media only screen and (min-width: $lg-screen) {
      padding: 10px 5%;
    }
  }
  .row-container {
    @include box;
    .panel {
      overflow: hidden;
      @include transition;
      .panel-content {
        border-top: 1px solid rgba($dark, 0.2);
        margin: 0px 20px;
        padding: 10px 0px;
      }
    }
  }
  .row {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-decoration: none;
    color: $dark;
    &:not(.label) {
      height: 60px;
      .number {
        display: flex;
        justify-content: space-between;
        span {
          display: flex;
          align-items: center;
        }
      }
    }
    .clock {
      position: absolute;
      top: 13px;
      left: -16px;
      width: 34px;
      height: 34px;
      color: $white;
      background-color: $secondary-color;
      border-radius: 25px;
      padding: 6px;
    }
    .col {
      width: calc((100% - 300px) / 7 );
      display: flex;
      align-items: center;
      padding: 0px 20px;
      &.number {
        width:  300px;
        padding: 10px 10px 10px 30px;
      }
      &.status {
        justify-content: flex-end;
        color : #5E6066;
        &.is-active {
          font-weight: bold;
          color: $primary-color;
        }
      }
      .icon {
        @include icon;
      }
      .prev {
        opacity: 0.4;
        font-style: italic;
      }
    }
    .toggle {
      @include btn;
      position: relative;
      display: inline-block;
      font-size: 14px;
      padding: 0px;
      & svg {
        color: $primary-light;
        &:hover {
          color: $primary-color;
        }
      }
    }    
  }
}