@import '../../styles/variables.scss';

.container {
  padding: 10px 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: $secondary-color;
  animation-name: stop;
  animation-duration: 0.2s;
  @include animation-mixin-y(stop, -60px, 0px );
  pointer-events: none;
  &.is-edit {
    pointer-events: all;
  }
  &:nth-child(2n) {
    background-color: rgba($secondary-color, 0.9);
  } 
  svg {
    margin-right: 0px;
  }
  label {
    color: $white;
  }
  input {
    font-size: 14px;
    background: transparent;
    border-style: none;
    border-bottom: 1px solid rgba($white, 0.4);
    color: $white;
    font-weight: bold;
    &:focus {
      outline: none;
      border-bottom: 1px solid rgba($white, 1);
    }
  }
  .col {
    width: calc(100% / 4);
    padding: 10px 10px 10px 0px;
    color: $white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:first-child {
      svg {
        margin-right: 10px;
      }
    }
    &:not(:first-child) {
      padding: 10px 20px;
      border-left: 1px solid rgba($white, 0.4);
      align-items: flex-end;
    }
  }
  .check {
    @include btn;
    padding: 0px;
    color: transparent;
    pointer-events: none;
    &.not-saved {
      color: $white;
      pointer-events: all;
    }
  }
}