@import '../../../styles/variables.scss';

@mixin icon {
  @include transition;
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid #9286C4;
  box-shadow: 0px 10px 28px #EBECED;
  border-radius: 1px;
  z-index: 2;
  transform: rotate(45deg);
  cursor: pointer;
  &:hover {
    transform: scale(1.2) rotate(45deg);
  }
}

@mixin duration {
  background-color: #BEB2EF;
  width: 100%;
  height: 26px;
  transform: translateY(-2px);
  z-index: -1;
}

@mixin clock-icon {
  content: " ";
  display: block;
  background: $white;
  border: 1px solid #237EB1;
  height: 12px;
  width: 12px;
  border-radius: 12px;
  position: absolute;
  top: -4px;
  a {
    transform: translateY(-5px);
  }
}

.step {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  padding-top: 42px;
  font-size: 12px;
  a {
    display: block;
    color:rgba($dark , 0.5);
    text-transform: uppercase;
    transform: translateY(-16px);
    &.prev {
      transform: translateY(0px);
    }
  }
  &:hover {
    .tooltip {
      opacity: 1;
      top: 60px;
      &.clock {
        top: 80px;
      }
    }
  }
  .submition {
    cursor: pointer;
    @include icon;
    background-color: #F8BBBB;
  }
  .approbation-ANSM {
    @include icon;
    background-color: rgba(209, 246, 130, 1);
  }
  .date {
    @include icon;
    background-color: #7FE3CC;
  }
  .duration {
    @include duration;
    height: 26px;
    transform: translateY(-2px);
  }
  .duration-prev {
    height: 6px;
    transform: translateY(5px);
    .prev-container {
      display: flex;
      justify-content: space-between;
      .prev-item {
        width: 10px;
        background-color: #BEB2EF;
        height: 6px;
      }
    }
  }
  .clockstop {
    position: relative;
    background-color: #237EB1;
    width: 100%;
    z-index: 1;
    cursor: pointer;
    height: 20px;
    transform: translateY(-2px);
    // &.prev {
    //   height: 6px;
    // }
    // &:after {
    //   @include clock-icon;
    // }
    // &:before {
    //   @include clock-icon;
    //   right: -2px;
    // }
  }
  .clock-label {
    margin-top: 10px;
    color: #237EB1;
    text-transform: uppercase;
    font-weight: bold;
    width: 180px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 5px;
    }
  }
  .tooltip {
    position: absolute;
    @include box;
    opacity: 0;
    pointer-events: none;
    top: 90px;
    z-index: 3;
    width: 120px;
    padding: 5px;
    text-align: center;
    &.clock {
      width: 180px;
      text-transform: uppercase;
    }
    label {
      color: rgba($dark , 0.5);
      font-weight: bold;
      @include label;
    }
  }
  p:not(.tooltip) {
    min-width: 180px;
    text-align: center;
    transform: translateY(-38px);
    font-weight: bold;
    color: #4B526D;
  }
}