@import '../../styles/variables.scss';

.edit-toggle {
  position: absolute;
  top: 55px;
  right: calc(5% + 10px);
  z-index: 300;
}

.container {
  @include page-container;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  .content {
    @include content;
    height: auto;
    padding-bottom: 80px;
    display: flex;
    justify-content: space-between;
    margin: 60px 0px;
    aside {
      width: 20%;
      padding-right: 30px;
      .link {
        display: flex;
        align-items: center;
        color: $primary-color;
        text-decoration: none;
        margin-bottom: 20px;
        transform: translateX(-10px);
        @include title-2;
        svg {
          @include transition;
        }
        &:hover {
          svg {
            transform: translateX(-5px);
          }
        }
      }
      hr {
        margin: 30px 0px;
        border-top: 1px solid #E4EAEE;
      }
      .delete {
        @include btn;
        @include text;
        padding: 0px;
        display: flex;
        align-items: center;
        color: #DC6541;
        text-decoration: underline;
        svg {
          margin-right: 10px;
        }
      }
    }
    main {
      width: 80%;
      @include box;
    }
  }
}