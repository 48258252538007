@import './mixins.scss';
@import './fonts-style.scss';

$bg-color: #FAFBFB;
$white: #FFFFFF;
$black: #181844;
$dark: #192243;
$dark-lighter: #5F657C;
$primary-color: #40B7A1;
$primary-dark: #379e8b;
$primary-light: #B5E0DC;
$primary-extra-light: rgba(118, 230, 232, 0.235);

$secondary-color: #237EB1;


$dark-grey:#606060;
$grey:#D8D8D8;
$light-grey: #E3E3E3;
$orange: #FF8469;

$text-color-light: #E3E3E3;
$success: #4DCD98;
$danger : red;

$shadow: 0px 2px 21px 2px rgba(24, 24, 68, 0.0669799);

$card-shadow: 0px 10px 20px -4px rgba(136,136,136,0.25);
$card-shadow-hover: 0px 10px 15px 8px rgba(136,136,136,0.35);

$panel-shadow: 0px 5px 8px 10px rgba(136,136,136,0.35);

$shadow-hover: 0px 0px 10px -2px rgba(80, 80, 80, 0.603);

$lg-screen: 1200px;
$md-screen: 1024px;
$sm-screen: 768px;
$xs-screen: 576px;

$margin-md : 20px;
$margin-sm : 15px;
$margin-xs : 10px;

$small : 13px;
$regular : 16px;
$h2 : 18px;
$h1 : 25px;

$radius: 3px;

@font-face {
	font-family: "Anybody-Bold";
	src: url("../assets/fonts/Anybody-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Anybody-SemiBold";
	src: url("../assets/fonts/Anybody-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Anybody-Regular";
	src: url("../assets/fonts/Anybody-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Ubuntu-Regular";
	src: url("../assets/fonts/Ubuntu-Regular.ttf") format("truetype");
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  box-shadow: 0 0 0 30px $primary-extra-light inset !important;
}