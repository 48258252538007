@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input-password{
  position: relative;

  label{
    @include label;
  }

  &.inline {
    @include inline;
  }

  &:not(.inline){
    .container-input {
      margin-top: 10px;
    }
  }

  input {
    @include input;
    &::placeholder {
      font-size: 14px;
    }
  }
  &.primary {
    @include primary;
  }
  &.login {
    @include login;
  }

}

.container-input{
  position: relative;
  width: 100%;

  .container-icon{
    display: block;
    position: absolute;
    top:0;
    right:0;
    height: 36px;
    width: 36px;
    cursor: pointer;
    color: $primary-color;
    svg {
      position: absolute;
      color: $primary-color;
      top:69%;
      left:36%;
      transform: translate(-50%,-50%);
    }
  }

}

.container-input .input {
  display: block;
  padding: 10px 36px 10px 10px;
  border: none;
  outline: none;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  min-width: 250px;
  box-sizing: border-box;

  &::-ms-reveal,
  &::-ms-clear{
    display: none;
  }

  // HIDE KEY ON SAFARI
  &::-webkit-credentials-auto-fill-button {
    margin: 0;
    width: 0;
    background-color: transparent;
  }

}
