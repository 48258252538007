@import '../../styles/variables.scss';

.container {
  margin-bottom: 20px;
  animation-name: timeline;
  animation-duration: 0.2s;
  @include animation-fade(timeline);
    
  
  .variation {
    position: relative;
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
  }
}