@import '../../styles/variables.scss';

.container {
  width: 100%;
  label {
    color: rgba($dark , 0.5);
    display: inline-block;
    margin-bottom: 5px;
  }
  .value {
    width: 100%;
    text-align: center;
    padding: 6px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 3px;
    &.is-active {
      background: $primary-light;
    }
  }
  &.text-left {
    margin-top: 30px;
    .options {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      button {
        margin-bottom: 10px;
      }
    }
    .value {
      text-align: left;
      padding: 6px 10px;
    }
  }
  &.header {
    margin-bottom: 20px;
  }
  &.width-auto {
    width: auto;
  }
  &.status {
    margin-bottom: 20px;
    .options {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      button {
        margin-bottom: 10px;
      }
    }
    .value {
      border: 1px solid $primary-color;
      padding: 10px;
      color: $primary-color;
    }
  }
  &.tooltip {
    .value {
      width: 120px;
    }
  }
  .options {
    display: flex;
    button {
      @include btn;
      border: 1px solid rgba($dark , 0.3);
      border-radius: 3px;
      font-size: 16px;
      padding: 5px 14px ;
      color: rgba($dark , 0.4);
      margin-right: 10px;
      &:hover {
        background-color: rgba($dark , 0.2);
      }
      &.is-active {
        background: $primary-light;
        border: 1px solid rgba($primary-light , 0.5);
        font-weight: bold;
        color: $dark;
      }
    }
  }
}