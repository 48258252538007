@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input{

  label{
    @include label;
  }

  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.input {
      margin-top: 10px;
    }
  }

  .input {
    @include input;
  }
  &.primary {
    @include primary;
  }
  &.login {
    @include login;
  }
  &.number {
    width: 100%;
    .input {
      @include input;
      min-height: 40px;;
      background-color: rgba($white , 0.2);
      border-bottom: 1px solid #8A8E9F;
      color: $black;
      padding: 5px 0px;
      @include title-3;
      &:focus {
        border-bottom: 1px solid $primary-color;
      }
    }
  }

}
