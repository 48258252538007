@import '../../../../styles/variables.scss';

.container {
  position: relative;
  padding-bottom: 30px;
  width: 100% ;
  .btn {
    bottom: -40px;
    position: absolute;
    padding-right: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    svg {
      margin: 0px 15px 0px 0px ;
    }
  }
}