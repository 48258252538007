@import '../../../../styles/variables.scss';

.container {
  border-top: 1px solid #E4EAEE;
  padding: 30px;
  width: 100%;
  height: auto;
  h2 {
    margin-top: 0px;
    font-size: 16px;
    font-weight: bold;
  }
  .comment {
    padding: 20px ;
    margin-bottom: 20px;
    width: 600px;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px #EBECED;
    border-radius: 3px;
    p {
      margin: 0px 0px 20px 0px;
    }
    .header {
      display: flex;
      justify-content: space-between;
      p {
        margin: 0px;
        color: rgba($dark ,0.3);
        &:last-child {
          color: $dark;
          font-weight: bold;
        }
      }
    }
  }
  .textarea {
    margin: 20px 0px;
    width: 600px;
  }
}